import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GuestDialogComponent } from '../shared/dialogs/guest-dialog/guest-dialog.component';
import { GuestInterface } from '../shared/interfaces';
import { GeneralService } from '../shared/services/general.service';
import moment from 'moment';
import 'moment/locale/de-ch';


@Component({
  selector: 'app-guests',
  templateUrl: './guests.component.html',
  styleUrl: './guests.component.scss'
})
export class GuestsComponent implements OnInit {
  public guests: GuestInterface[];

  constructor(
    private dialog: MatDialog,
    private generalService: GeneralService
  ) { }

  ngOnInit(): void {
    moment.locale('de-ch')
    this.getGuests()
  }

  getGuests() {
    this.generalService.getGuests().subscribe({
      next: data => {
        data.access_codes.forEach(element => {
          if(moment(element.to_date).isBefore(moment()))
            element.is_active = false;
          element.from_date = moment(element.from_date).format('LT') + ' - ' + moment(element.from_date).format('L')
          element.to_date = moment(element.to_date).format('LT') + ' - ' + moment(element.to_date).format('L')
          
        });
        this.guests = data.access_codes;
        console.log(this.guests)
      },
      error: err => {
        console.log(err)
      }
    })
  }

  async openEdit(guestId?:number) {
    const dialogRef = this.dialog.open(GuestDialogComponent, {
      panelClass: 'guest-dialog',
      data: {guestId: guestId}
    });
    dialogRef.afterClosed().subscribe(val=>{
      this.getGuests()
    })
  }
}
