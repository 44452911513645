<mat-card class="login-card" appearance="outlined">
    <mat-card-header>
        <mat-card-title>Mit Sicherheitspin einloggen</mat-card-title>
    </mat-card-header>
    <form [formGroup]="form" *ngIf="form">
        <mat-card-content>
            <p>Geben Sie die im Vertrag hinterlegte Mobilnummer ein inklusive Ihren Sicherheitspin. Anschliessend senden wir Ihnen per SMS den Zugangscode für die Web-App.</p>
            <mat-form-field>
                <mat-label>Ihre alte Mobile Nr.</mat-label>
                <input matInput formControlName="old_number" placeholder="+41000000000">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Ihre neue Mobile Nr.</mat-label>
                <input matInput formControlName="new_number" placeholder="+41000000000">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Ihr Sicherheitspin</mat-label>
                <input matInput formControlName="pin">
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions>
            <button class="secondary-btn" type="button" [routerLink]="['/auth/login']" mat-button>Abbrechen
            </button>
            <button type="submit" class="save-btn" [disabled]="form.invalid" [ngStyle]="{'opacity': form.invalid ? '0.5' : '1'}"
                mat-flat-button (click)="submit()">Senden</button>
        </mat-card-actions>
    </form>
</mat-card>
