import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../../environments/environment";
import { StorageService } from "./storage.service";
import { RoutesConstants, StorageConstants } from "../constants";
import { DoorInterface, ElevatorInterface, LocationInterface, OverviewInterface, OverviewStatusInterface } from "../interfaces";
import { LoginService } from "./login.service";

@Injectable({
    providedIn: 'root'
})
export class LocationService {
    private readonly _apiUrl = environment.api_url;
    private accessHeaders = new HttpHeaders({ 'access_code': LoginService.accessCode.value! });

    constructor(
        private router: Router,
        private http: HttpClient,
    ) { }

    sendNumber(body: FormData) {
        return this.http.post<any>(this._apiUrl + `pwa/login/phonenumber`, body);
    }

    checkAccessCode(accessCode: string) {
        return this.http.get<{ access_token: string }>(this._apiUrl + `pwa/access_code/${accessCode}`);
    }

    getLocations() {
        return this.http.get<{ locations: LocationInterface[] }>(this._apiUrl + `pwa/user/locations`, { headers: this.accessHeaders });
    }

    getOverviewStatus(locationId: number) {
        return this.http.get<{ data: OverviewStatusInterface[] }>(this._apiUrl + `pwa/overview_status?location_id=${locationId}`, { headers: this.accessHeaders });
    }

    overview(locationId: number) {
        return this.http.get<OverviewInterface>(this._apiUrl + `pwa/overview?location_id=${locationId}`, { headers: this.accessHeaders });
    }
    openArea(body: { area_id: number }) {
        return this.http.post<any>(this._apiUrl + `pwa/open_area`, body, { headers: this.accessHeaders });
    }

    openElevator(body: { floor: number, dyn_id: number }) {
        return this.http.post<any>(this._apiUrl + `pwa/lock_elevator_action`, body, { headers: this.accessHeaders });
    }

    openSystemTool(body: { location_id: number, area_number: string }) {
        return this.http.post<any>(this._apiUrl + `pwa/tools/open_system`, body);
    }
}