import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService, StorageService } from '../../shared/services';
import { RoutesConstants, StorageConstants } from '../../shared/constants';

@Component({
  selector: 'app-check-link',
  templateUrl: './check-link.component.html',
  styleUrl: './check-link.component.scss'
})
export class CheckLinkComponent implements OnInit {
  public hasError: boolean = false;
  public hasSuccess: boolean = false;
  public greeting: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
    const accessCode = this.route.snapshot.paramMap.get('access_code')!;
    if (!accessCode) {
      this.hasError = true;
    } else {
      this.loginService.checkAccessCode(accessCode).subscribe({
        next: data => {
          if (data.user) {
            this.hasSuccess = true;
            this.greeting = data.greeting + ' ' + data.user.firstname;
            setTimeout(() => {
              StorageService.setItem(StorageConstants.ACCESS_CODE, accessCode);
              StorageService.setItem(StorageConstants.USER, data.user);
              LoginService.accessCode.next(accessCode);
              LoginService.user.next(data.user);
              this.router.navigate([RoutesConstants.HOME]);
            }, 1500);
          } else {
            this.hasError = true;
          }
        },
        error: err => {
          console.log(err);
          this.hasError = true;
        }
      })
    }

  }
}
