import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../../environments/environment";
import { StorageService } from "./storage.service";
import { RoutesConstants, StorageConstants } from "../constants";
import { DoorInterface, ElevatorInterface, GuestEditInterface, GuestInterface, LocationInterface, OverviewInterface } from "../interfaces";
import { LoginService } from "./login.service";

@Injectable({
    providedIn: 'root'
})
export class GeneralService {
    private readonly _apiUrl = environment.api_url;
    private accessHeaders = new HttpHeaders({ 'access_code': LoginService.accessCode.value! });

    constructor(
        private router: Router,
        private http: HttpClient,
    ) { }

    getGuests() {
        return this.http.get<{ access_codes: GuestInterface[] }>(this._apiUrl + `pwa/guests`, { headers: this.accessHeaders });
    }

    getGuest(guestId: number) {
        return this.http.get<any>(this._apiUrl + `pwa/guests/detail/${guestId}`, { headers: this.accessHeaders });
    }

    updateGuest(body: GuestEditInterface, guestId: number) {
        return this.http.post<any>(this._apiUrl + `pwa/guests/detail/${guestId}`, body, { headers: this.accessHeaders });
    }

    deleteGuest(guestId: number) {
        return this.http.delete<any>(this._apiUrl + `pwa/guests/detail/${guestId}`, { headers: this.accessHeaders });
    }

    createGuest(body: GuestEditInterface) {
        return this.http.post<{ access_code: GuestInterface }>(this._apiUrl + `pwa/guests`, body, { headers: this.accessHeaders });
    }

}