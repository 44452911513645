<mat-card class="login-card" appearance="outlined">
    <mat-card-header>
        <mat-card-title>Login</mat-card-title>
    </mat-card-header>
    <form>
        <mat-card-content>
            <p>Sie sind bereits Zebrabox-Kunde? Melden Sie sich erstmalig an, um den Zugang zur Web-App freizuschalten.
            </p>
            <mat-form-field>
                <mat-label>Mobile Nr.</mat-label>
                <input matInput [(ngModel)]="mobile" [ngModelOptions]="{standalone: true}" placeholder="+41000000000">

            </mat-form-field>
        </mat-card-content>
        <mat-card-actions>
            <button type="submit" class="save-btn" [disabled]="!mobile" [ngStyle]="{'opacity': mobile ? '1' : '0.5'}"
                mat-flat-button (click)="submit()">Senden</button>
        </mat-card-actions>
    </form>
    <mat-divider></mat-divider>
    <mat-card-content>
        <p>Sie haben die Telefonnummer gewechselt? Melden Sie sich hier mit Ihrem Sicherheitspin an.</p>
    </mat-card-content>
    <mat-card-actions>
        <button class="secondary-btn" [routerLink]="['/auth/security-pin']" mat-button>Mit Sicherheitspin einloggen
        </button>
    </mat-card-actions>
</mat-card>