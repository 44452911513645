<div class="drag-container" *ngIf="online">
    <div class="dragger-text" *ngIf="!isUnlocked"><mat-icon>arrow_forward</mat-icon>
        <div>Nach rechts<br>ziehen</div>
    </div>
    <div class="dragger-text" *ngIf="isUnlocked"><mat-icon>check_circle_outlined</mat-icon>
        <div>Aktiviert</div>
    </div>
    <div class="dragger" #dragger (pointerdown)="startDrag($event)"><mat-icon>{{isUnlocked ? 'lock_open_outlined' :
            'lock_outlined'}}</mat-icon></div>
    <div class="unlocker"><mat-icon>lock_open_outlined</mat-icon></div>
</div>
<div class="offline-container" *ngIf="!online">
    <div>
        Verbinden Sie sich mit dem<br>Wifi "Zebrabox_Access"
    </div>
    <lottie-player autoplay src="/assets/lottie/lottie-wifi.json" loop mode="normal">
    </lottie-player>
</div>