import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { GeneralService } from '../../services/general.service';
import { LoginService } from '../../services';
import { lastValueFrom } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-change-pin-dialog',
  templateUrl: './change-pin-dialog.component.html'
})
export class ChangePinDialogComponent implements OnInit {

  public form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ChangePinDialogComponent>,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.initForm()
  }

  public initForm() {
    this.form = this.formBuilder.group({
      currentPin: ['', Validators.required],
      newPinA: ['', Validators.required],
      newPinB: ['', Validators.required],
    })
  }

  public async submit() {
    try {
      console.log(this.form.value)
      let formData = new FormData();
      formData.append('current-pin', this.form.value.currentPin)
      formData.append('new-pin-a', this.form.value.newPinA)
      formData.append('new-pin-b', this.form.value.newPinB)
      const data = await lastValueFrom(this.loginService.changePin(formData));
      console.log(data)
      this.dialogRef.close();
      this.snackBar.open('Pin erfolgreich geändert', undefined, { duration: 30000, panelClass: 'success-snack' })
    } catch (error) {
      console.log((error as HttpErrorResponse).error.info)
      this.snackBar.open((error as HttpErrorResponse).error.info, undefined, { duration: 30000, panelClass: 'error-snack' })
    }
  }

}
