import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { LocationInterface } from '../shared/interfaces';
import { LocationService } from '../shared/services';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-system-test',
  templateUrl: './system-test.component.html',
  styleUrl: './system-test.component.scss'
})
export class SystemTestComponent implements OnInit {
  public locations: LocationInterface[] = [];
  public selectedLocation: number;
  public storageNumber: string;

  constructor(
    private locationService: LocationService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.locationService.getLocations().subscribe({
      next: data => {
        this.locations = data.locations;
        if (this.locations.length) {
          this.selectedLocation = this.locations[0].id
        }
      }
    })
  }

  public locationChanged(event: MatSelectChange) {
    this.selectedLocation = event.value
  }

  submit() {
    const body = {
      location_id: this.selectedLocation,
      area_number: this.storageNumber
    }
    this.locationService.openSystemTool(body).subscribe({
      next: data => {
        console.log(data)
        this.snackBar.open('Öffnung angestossen', undefined, { duration: 3000, panelClass: 'success-snack' })
      },
      error: err => {
        console.log(err)
        this.snackBar.open(err.error.message, undefined, { duration: 30000, panelClass: 'error-snack' })
      }
    })
  }
}
