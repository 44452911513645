import { Component } from '@angular/core';
import { LoginService } from '../../shared/services';
import { Router } from '@angular/router';
import { RoutesConstants } from '../../shared/constants';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  public mobile: string;
  public submitting: boolean = false;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  public submit() {
    if (this.submitting) return;
    this.submitting = true;
    let formData = new FormData();
    formData.append('phonenumber', this.mobile);
    this.loginService.sendNumber(formData).subscribe({
      next: data => {
        console.log(data)
        if (data.info == 'Data could be processed successfully') {
          this.router.navigate([RoutesConstants.LOGIN_SUCCESS]);
        }
      },
      error: err => {
        this.snackBar.open(err.error.message, undefined, { duration: 30000, panelClass: 'error-snack' });
        this.submitting = false;
      }
    })
  }
}
