import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../shared/services';
import { ProfileInterface } from '../shared/interfaces';
import { MatDialog } from '@angular/material/dialog';
import { ChangePinDialogComponent } from '../shared/dialogs/change-pin-dialog/change-pin-dialog.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit {

  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.loginService.getProfile().subscribe({
      next: data => {
        this.initForm(data.user)
      }
    })
  }

  public initForm(user: ProfileInterface) {
    this.form = this.formBuilder.group({
      firstname: [user.firstname, Validators.required],
      lastname: [user.lastname, Validators.required],
      address: [user.address, Validators.required],
      zip: [user.zipcode, Validators.required],
      city: [user.location, Validators.required],
      mobile: [user.phone, Validators.required],
      email: [user.email, Validators.required]
    })
  }

  newCode() {
    const dialogRef = this.dialog.open(ChangePinDialogComponent, {
      panelClass: 'guest-dialog',
    });
  }

}
