<mat-card class="login-card" appearance="outlined">
    <ng-container *ngIf="!hasError && !hasSuccess">
        <mat-card-header>
            <mat-card-title>Code wird überprüft...</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <p>Bitte warten Sie einen Moment. Sie werden gleich weitergeleitet.</p>

        </mat-card-content>

    </ng-container>
    <ng-container *ngIf="hasError">
        <mat-card-header>
            <mat-card-title>Code fehlerhaft</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <p>Dieser Link konnte nicht erkannt werden. Bitte versuchen Sie es erneut.</p>

        </mat-card-content>
        <mat-card-actions>
            <button class="save-btn" mat-flat-button [routerLink]="['/auth/login']">Zur Startseite</button>
        </mat-card-actions>
    </ng-container>
    <ng-container *ngIf="hasSuccess">
        <mat-card-header>
            <mat-card-title>{{greeting}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <p>Willkommen bei Zebrabox. Sie werden gleich zur Übersicht weitergeleitet.</p>
        </mat-card-content>
    </ng-container>
</mat-card>