<mat-card class="login-card" appearance="outlined">
    <mat-card-header>
        <mat-card-title>Login erfolgreich</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p>Ihre Anmeldung war erfolgreich. Sie werden in Kürze eine SMS mit dem Anmdeldelink erhalten.</p>

    </mat-card-content>
    <mat-card-actions>
        <button  class="save-btn"  mat-flat-button [routerLink]="['/auth/login']">Zur Startseite</button>
    </mat-card-actions>
</mat-card>
