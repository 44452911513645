<div class="system-container">

    <div class="page-title-container"><button mat-icon-button class="back-button"
            [routerLink]="['/home']"><mat-icon>arrow_back</mat-icon></button>
        <h3>Systemtest</h3>
        <div class="left-title"></div>
    </div>
    <form>
        <mat-form-field appearance="outline">
            <mat-label>Standort</mat-label>
            <mat-select [(ngModel)]="selectedLocation" [ngModelOptions]="{standalone: true}"
                (selectionChange)="locationChanged($event)">
                @for (location of locations; track location.id) {
                <mat-option [value]="location.id">{{location.name}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Area Nr.</mat-label>
            <input matInput [(ngModel)]="storageNumber" [ngModelOptions]="{standalone: true}">

        </mat-form-field>


        <button mat-flat-button type="submit" (click)="submit()" class="def-btn">Öffnen
        </button>
    </form>
</div>