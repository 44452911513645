import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { RoutesConstants, StorageConstants } from "../constants";
import { LoginService, StorageService } from "../services";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let accessCode = LoginService.accessCode.value;
        let user = LoginService.user.value;

        if (!accessCode) {
            accessCode = StorageService.getItem(StorageConstants.ACCESS_CODE);
            LoginService.accessCode.next(accessCode);
        }

        if (!user) {
            user = StorageService.getItem(StorageConstants.USER);
            LoginService.user.next(user);
        }

        if (accessCode && user) {
            this.router.navigate([RoutesConstants.HOME]);
            return false;
        }

        if (accessCode || user) {
            StorageService.removeItem(StorageConstants.ACCESS_CODE);
            LoginService.accessCode.next(null);
            StorageService.removeItem(StorageConstants.USER);
            LoginService.user.next(null);
        }
        return true;
    }
}