import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: '[slide-to-unlock]',
  templateUrl: './slide-to-unlock.component.html',
  styleUrl: './slide-to-unlock.component.scss'
})
export class SlideToUnlockComponent {
  @ViewChild('dragger') private dragger: ElementRef;
  @HostListener('document:pointermove', ['$event'])
  onPointerMove(event: any) {
    if (this.selectedElement)
      this.elementDrag(event);
  }
  @HostListener('document:pointerup', ['$event'])
  onPointerUp(event: any) {
    if (this.selectedElement)
      this.stopDrag();
  }

  @Output() unlocked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() online: boolean = false;
  selectedElement: HTMLElement | null = null;
  spaceToLeft: number = 0;
  public isUnlocked: boolean = false;


  startDrag(event: Event) {
    this.selectedElement = event.target as HTMLElement;
    this.spaceToLeft = ((event as MouseEvent).clientX - this.selectedElement!.parentElement!.offsetLeft - 5);
  }
  elementDrag(event: any) {
    if (this.isUnlocked) return
    event = event || window.event;
    const parentWidth = this.selectedElement!.parentElement!.offsetWidth - 76;
    if (((100 / parentWidth) * (this.selectedElement!.offsetLeft)) > 100) {
      this.success()
      return
    } else if (((100 / parentWidth) * (this.selectedElement!.offsetLeft)) < 0) {
      this.selectedElement!.style.left = "5px"
      this.selectedElement = null;
      return
    }
    event.preventDefault();
    const left = event.clientX - this.selectedElement!.parentElement!.offsetLeft - this.spaceToLeft;
    this.selectedElement!.style.left = (left) + "px";
  }
  stopDrag() {
    const parentWidth = this.selectedElement!.parentElement!.offsetWidth - 76;
    if (((100 / parentWidth) * (this.selectedElement!.offsetLeft)) > 95) {
      this.success()
    } else {
      this.selectedElement!.style.left = "5px"
      this.selectedElement = null;
    }
  }
  success() {
    this.selectedElement!.style.left = "unset";
    this.selectedElement!.style.right = "5px";
    this.selectedElement!.parentElement!.classList.add('unlocked');
    this.isUnlocked = true;
    this.unlocked.emit(true);
    setTimeout(() => {
      this.selectedElement!.style.right = "unset"
      this.selectedElement!.style.left = "5px"
      this.selectedElement!.parentElement!.classList.remove('unlocked');
      this.isUnlocked = false;
      setTimeout(() => {
        this.selectedElement = null;
      }, 200);

    }, 3000);
  }
}
