<div class="home-container">
    <mat-form-field appearance="outline">
        <mat-label>Standort</mat-label>
        <mat-select [(ngModel)]="selectedLocation" (selectionChange)="locationChanged($event)">
            @for (location of locations; track location.id) {
            <mat-option [value]="location.id">{{location.name}}</mat-option>
            }
        </mat-select>
    </mat-form-field>
    <div class="buttons">
        <button mat-flat-button [ngClass]="{'active': selectedType == devicesEnum.storage}"
            (click)="this.selectedType = devicesEnum.storage">Lagerraum</button>
        <button mat-flat-button [ngClass]="{'active': selectedType == devicesEnum.elevator}"
            (click)="this.selectedType = devicesEnum.elevator">Türen/Lift</button>
    </div>
    <div class="devices-container" *ngIf="overview">
        @if (selectedType == devicesEnum.storage){
        @for (device of overview.areas; track device){
        <mat-card class="device-card" appearance="outlined">
            <mat-card-header>
                <mat-card-title>{{'Lagerraum '+device.number}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <ul class="status-list">
                    <li>
                        <mat-icon class="material-symbols-outlined">meeting_room</mat-icon>
                        <div>Türe</div>
                        <div>{{device.status_object ? device.status_object.lock_door : '---'}}</div>
                    </li>
                    <li>
                        <mat-icon>lock_outlined</mat-icon>
                        <div>Schloss</div>
                        <div>{{device.status_object ? device.status_object.lock_lock : '---'}}</div>
                    </li>
                    <li>
                        <mat-icon class="material-symbols-outlined">wb_twilight</mat-icon>
                        <div>Alarm</div>
                        <div>{{device.status_object ? device.status_object.alarm : '---'}}</div>
                    </li>
                    <li>
                        <mat-icon class="material-symbols-outlined">settings</mat-icon>
                        <div>Status</div>
                        <div class="status-color" [ngClass]="{'green':device.status_object?.status}">
                            {{device.status_object ? (device.status_object.status ? 'Aktiv' :
                            'Inaktiv') : '---'}}</div>
                    </li>
                </ul>
            </mat-card-content>
            <mat-card-actions>
                <div class="dragger" slide-to-unlock [online]="connected"
                    (unlocked)="unlocked(device.id, devicesEnum.storage)"></div>
                <div class="card-footer">Zugang zum Lager: 06:00 bis 22:00 h</div>
            </mat-card-actions>
        </mat-card>
        }
        @if (!overview.areas.length){
        <div class="error-message">Keine Laggerräume vorhanden.</div>
        }

        } @else {

        @for (device of overview.elevators; track device){
        <mat-card class="device-card" appearance="outlined">
            <mat-card-header>
                <mat-card-title><mat-icon
                        class="material-symbols-outlined">elevator</mat-icon>{{device.name}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p>Rufen Sie den Warenlift zur Etage, in der Sie sich befinden. Wählen Sie im Anschluss die Etage aus,
                    in die Sie fahren möchten.</p>
                @for (floor of device.floors;track floor){
                <div class="dragger-title"><mat-icon class="material-symbols-outlined">elevator</mat-icon>{{'Lift-Etage
                    '+floor.floor_name}}
                </div>
                <div class="dragger" slide-to-unlock [online]="connected"
                    (unlocked)="unlocked(device.dyn_id, devicesEnum.elevator,floor.floor)"></div>
                }

            </mat-card-content>
        </mat-card>
        }
        @if (overview.doors.length){
        <mat-card class="device-card" appearance="outlined">
            <mat-card-header>
                <mat-card-title><mat-icon
                        class="material-symbols-outlined">meeting_room</mat-icon>Türen</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                @for (device of overview.doors; track device){
                <div class="dragger-title"><mat-icon
                        class="material-symbols-outlined">meeting_room</mat-icon>{{device.name}}
                </div>
                <div class="dragger" slide-to-unlock [online]="connected"
                (unlocked)="unlocked(device.dyn_id, devicesEnum.elevator,-1)"></div>
                }

            </mat-card-content>
        </mat-card>

        }
        @if (!overview.doors.length && !overview.elevators.length){
        <div class="error-message">Keine Türen/Lifts vorhanden.</div>
        }
        }

    </div>

</div>