<h1 mat-dialog-title>{{guest ? 'Gast bearbeiten' : 'Gast einladen'}}<mat-icon
        [mat-dialog-close]="false">close</mat-icon></h1>
<mat-dialog-content>
    <form [formGroup]="form" *ngIf="form">
        <div class="mat-slide-toggle-container">Status: <span
                [ngClass]="{'active': form.controls['active'].value}">{{(form.controls['active'].value ? 'Aktiv':
                'Inaktiv')}}</span>
            <mat-slide-toggle formControlName="active"></mat-slide-toggle>
        </div>
        <mat-form-field appearance="outline">
            <mat-label>Vorname</mat-label>
            <input type="text" matInput formControlName="firstname">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Nachname</mat-label>
            <input type="text" matInput formControlName="lastname">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Mobile Nr.</mat-label>
            <input type="tel" matInput formControlName="mobile" placeholder="0123456789">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Startdatum</mat-label>
            <input matInput readonly [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" formControlName="start">
            <owl-date-time [pickerMode]="'dialog'" #dt1></owl-date-time>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Enddatum</mat-label>
            <input matInput readonly [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" formControlName="end">
            <owl-date-time [pickerMode]="'dialog'" #dt2></owl-date-time>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button class="delete-btn" mat-button type="button" (click)="deleteGuest()">{{guest ? 'Löschen' : 'Abbrechen'}}
    </button>
    <button [disabled]=" form && !form.valid" cdkFocusInitial class="save-btn" mat-flat-button (click)="submit()">Speichern
    </button>
    <button mat-icon-button class="icon-btn" cdkfo *ngIf="guest"><mat-icon class="material-symbols-outlined">sms</mat-icon></button>
</mat-dialog-actions>