import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { StorageConstants } from "../constants";
import { LoginService, StorageService } from "../services";

@Injectable()
export class AppGuard implements CanActivate {

    constructor(
        private router: Router,
        private loginService: LoginService
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let accessCode = LoginService.accessCode.value;
        let user = LoginService.user.value;

        if (!accessCode) {
            accessCode = StorageService.getItem(StorageConstants.ACCESS_CODE);
            LoginService.accessCode.next(accessCode);
        }

        if (!user) {
            user = StorageService.getItem(StorageConstants.USER);
            LoginService.user.next(user);
        }

        if (accessCode && user) return true;

        this.loginService.logout();
        return false;
    }
}