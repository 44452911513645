import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../../environments/environment";
import { StorageService } from "./storage.service";
import { RoutesConstants, StorageConstants } from "../constants";
import { ProfileInterface } from "../interfaces";

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    public static accessCode: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    public static user: BehaviorSubject<ProfileInterface | null> = new BehaviorSubject<ProfileInterface | null>(null);
    private readonly _apiUrl = environment.api_url;

    constructor(
        private router: Router,
        private http: HttpClient,
    ) { }

    logout() {
        StorageService.removeItem(StorageConstants.ACCESS_CODE);
        LoginService.accessCode.next(null);
        StorageService.removeItem(StorageConstants.USER);
        LoginService.user.next(null);
        this.router.navigate([RoutesConstants.LOGIN]);
    }

    pinLogin(body: FormData){
        return this.http.post<any>(this._apiUrl + `pwa/login/pin`, body);
    }

    sendNumber(body: FormData) {
        return this.http.post<any>(this._apiUrl + `pwa/login/phonenumber`, body);
    }

    checkAccessCode(accessCode: string) {
        return this.http.get<{ user: ProfileInterface, greeting:string  }>(this._apiUrl + `pwa/access_code/${accessCode}`);
    }

    getProfile() {
        return this.http.get<{ user: ProfileInterface}>(this._apiUrl + `pwa/profile`, { headers: new HttpHeaders({ 'access_code': LoginService.accessCode.value! }) });
    }

    changePin(body: FormData) {
        return this.http.post<any>(this._apiUrl + `pwa/login/change_pin`, body, { headers: new HttpHeaders({ 'access_code': LoginService.accessCode.value! }) });
    }

    checkQRCode(code: string){
        return this.http.get<{ user: ProfileInterface}>(this._apiUrl + `pwa/check_qrcode/${code}`);
    }
}