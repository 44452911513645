<div class="guests-container">


    <div class="page-title-container"><button mat-icon-button class="back-button"
            [routerLink]="['/home']"><mat-icon>arrow_back</mat-icon></button>
        <h3>Gästeverwaltung</h3><button mat-icon-button class="plus-button"
        (click)="openEdit()"><mat-icon>add</mat-icon></button>
    </div>
    <div class="guests-text">
        <p>Sie können bis zu 5 Gästen Zutritt<br>zu Ihrem Lagerraum erteilen.</p>
    </div>
    <div class="guest-cards-container">
        @for (guest of guests; track guest.id) {
        <mat-card class="guest-card" appearance="outlined">
            <mat-card-header>
                <mat-card-title><div class="status" [ngClass]="{'active': guest.is_active}"></div>{{guest.guest_firstname + ' '+ guest.guest_lastname}}<mat-icon (click)="openEdit(guest.id)">edit</mat-icon></mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <ul class="info-list">
                    <li>
                        <mat-icon class="material-symbols-outlined">lock_person</mat-icon>
                        <div>Startzeit</div>
                        <div>{{guest.from_date}}</div>
                    </li>
                    <li>
                        <mat-icon class="material-symbols-outlined">no_encryption</mat-icon>
                        <div>Endzeit</div>
                        <div>{{guest.to_date}}</div>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    }
    </div>
</div>