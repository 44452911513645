<div class="layout-container">
    <div class="layout-header">
        <img src="/assets/images/logo.svg" id="header-img" alt="logo">
        <div class="icons">
            <div class="profile"></div><a href="https://www.zebrabox.ch/de/ueber-zebrabox/kontakt" target="_blanc"><mat-icon class="material-symbols-outlined">help</mat-icon></a>
        </div>
    </div>
    <div class="layout-content"><router-outlet></router-outlet></div>
    <div class="layout-footer">Acces App 2.0.1</div>
</div>
