<div class="layout-outer-container">
    <div class="layout-container">
        <div class="layout-header">
            <img src="/assets/images/logo.svg" id="header-img" alt="logo">
            <div class="icons">
                <div class="profile" [routerLink]="['/profile']" [ngClass]="{'show-profile': user }">
                    <div class="profile-name" *ngIf="user">{{user.firstname}}<br>{{user.lastname}}</div>
                    <mat-icon class="material-symbols-outlined">
                        account_circle
                    </mat-icon>
                </div><mat-icon (click)="menu = true">menu</mat-icon>
            </div>
        </div>
        <div class="layout-content" #content (scroll)="onScroll($event)"><router-outlet></router-outlet></div>
        <div class="layout-footer">Acces App 2.0.1</div>
    </div>
    <div class="menu-outer-container" [ngClass]="{'open':menu}">
        <div class="menu-container">
            <mat-icon class="material-symbols-outlined close" (click)="menu = false">cancel</mat-icon>
            <ul>
                <li (click)="navigate('home')"><mat-icon class="material-symbols-outlined">apps</mat-icon>Übersicht</li>
                <li (click)="navigate('guests')"><mat-icon
                        class="material-symbols-outlined">supervised_user_circle</mat-icon>Gäste</li>
                <li (click)="navigate('profile')"><mat-icon
                        class="material-symbols-outlined">account_circle</mat-icon>Profil
                </li>
                <li (click)="navigate('system-test')"><mat-icon
                        class="material-symbols-outlined">build_circle</mat-icon>Systemtest</li>
                <li (click)="navigate('home')"><mat-icon
                        class="material-symbols-outlined">help_outline</mat-icon>Hilfe/Kontakt
                </li>
            </ul>
            <div class="logout" (click)="logout()"><mat-icon>logout</mat-icon>Abmelden</div>
        </div>
    </div>

</div>