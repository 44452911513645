import { Injectable } from "@angular/core"

@Injectable()
export class RoutesConstants {
  //auth
  public static get LOGIN() { return `auth/login` };
  public static get LOGIN_SUCCESS() { return `auth/success` };
  public static get SECURITY_PIN() { return `auth/security-pin` };


  public static get HOME() { return `home` };
}