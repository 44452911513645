import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GuestInterface } from '../../interfaces';
import { GeneralService } from '../../services/general.service';
import { lastValueFrom } from 'rxjs';
import moment from 'moment';
import { OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-guest-dialog',
  templateUrl: './guest-dialog.component.html',
  providers: [
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'de' }
  ]
})
export class GuestDialogComponent implements OnInit {

  public form: FormGroup;
  public submitting: boolean = false;
  public guest: GuestInterface | null = null;

  constructor(
    public dialogRef: MatDialogRef<GuestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      guestId?: number,
    },
    private formBuilder: FormBuilder,
    private generalService: GeneralService,
    private snackBar: MatSnackBar
  ) { }

  async ngOnInit() {
    if (this.data.guestId) {
      try {
        const data = await lastValueFrom(this.generalService.getGuest(this.data.guestId))
        this.guest = data.access_code;
      } catch (error) {
        console.log(error)
      }
    }
    this.initForm()
  }

  public initForm() {
    this.form = this.formBuilder.group({
      firstname: [this.guest ? this.guest.guest_firstname : '', Validators.required],
      lastname: [this.guest ? this.guest.guest_lastname : '', Validators.required],
      mobile: [this.guest ? this.guest.guest_phone : '', Validators.required],
      start: [this.guest ? this.guest.from_date : '', Validators.required],
      end: [this.guest ? this.guest.to_date : '', Validators.required],
      active: [this.guest ? this.guest.is_active : true, Validators.required],
    })
  }
  public async deleteGuest() {
    if (this.submitting) return;
    this.submitting = true;
    if (!this.guest) {
      this.dialogRef.close();
      return
    }
    try {
      await lastValueFrom(this.generalService.deleteGuest(this.data.guestId!))
      this.snackBar.open('Gast erfolgreich gelöscht', undefined, { duration: 30000, panelClass: 'success-snack' })
      this.dialogRef.close();
    } catch (error) {
      this.snackBar.open((error as HttpErrorResponse).error.message, undefined, { duration: 30000, panelClass: 'error-snack' })
      this.submitting = false;
    }

  }

  public async submit() {
    if (this.submitting) return
    this.submitting = true;
    let body = this.form.value;
    //get local iso string
    body.start = new Date(body.start).toLocaleString('sv').replace(' ', 'T')
    body.end = new Date(body.end).toLocaleString('sv').replace(' ', 'T')
    try {
      if (this.data.guestId) {
        await lastValueFrom(this.generalService.updateGuest(body, this.data.guestId))
        this.snackBar.open('Gast erfolgreich geändert', undefined, { duration: 30000, panelClass: 'success-snack' })
      } else {
        await lastValueFrom(this.generalService.createGuest(body))
        this.snackBar.open('Gast erfolgreich erstellt', undefined, { duration: 30000, panelClass: 'success-snack' })
      }
      this.dialogRef.close();
    } catch (error) {
      console.log(error)
      this.snackBar.open((error as HttpErrorResponse).error.message, undefined, { duration: 30000, panelClass: 'error-snack' })
      this.submitting = false;

    }
  }

}
