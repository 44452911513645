import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService, StorageService } from '../services';
import { StorageConstants } from '../constants';
import { ProfileInterface } from '../interfaces';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrl: './app-layout.component.scss'
})
export class AppLayoutComponent implements OnInit {
  @ViewChild('content') private content: ElementRef;

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    this.scrollFunction(event)
  }
  public menu: boolean = false;
  public user: ProfileInterface;
  constructor(
    private router: Router,
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
      setTimeout(() => {
        this.user = StorageService.getItem(StorageConstants.USER) as ProfileInterface;
        
      }, 1000);
  }

  public navigate(route: string) {
    this.router.navigateByUrl(route);
    this.menu = false;
  }
  
  scrollFunction(event: any) {
    if (this.content!.nativeElement.scrollTop > 50) {
      document.getElementById("header-img")!.style.height = "30px";
    } else {
      document.getElementById("header-img")!.style.height = "75px";
    }
  }

  logout() {
    this.loginService.logout();
  }
}
