import { CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import localeDECH from '@angular/common/locales/de-CH';
import { AppComponent } from './app.component';
import { provideRouter, RouterModule } from '@angular/router';
import { SlideToUnlockComponent } from './shared/components/slide-to-unlock/slide-to-unlock.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
registerLocaleData(localeDECH);

//material
import { MatIconModule } from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDividerModule} from '@angular/material/divider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';



import { AppLayoutComponent } from './shared/app-layout/app-layout.component';
import { HomeComponent } from './home/home.component';
import { GuestsComponent } from './guests/guests.component';
import { ProfileComponent } from './profile/profile.component';
import { routes } from './app.routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GuestDialogComponent } from './shared/dialogs/guest-dialog/guest-dialog.component';
import {provideNativeDateAdapter} from '@angular/material/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { AuthComponent } from './auth/auth.component';
import { LoginComponent } from './auth/login/login.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LoginSuccessComponent } from './auth/login-success/login-success.component';
import { SecurityPinComponent } from './auth/security-pin/security-pin.component';
import { CheckLinkComponent } from './auth/check-link/check-link.component';
import { AppGuard, AuthGuard } from './shared/guards';
import { SystemTestComponent } from './system-test/system-test.component';
import "@lottiefiles/lottie-player";
import { ChangePinDialogComponent } from './shared/dialogs/change-pin-dialog/change-pin-dialog.component';
import { QrCodeComponent } from './qr-code/qr-code.component';



const MATERIAL = [
  MatIconModule,
  MatSelectModule,
  MatFormFieldModule,
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatInputModule,
  MatDatepickerModule,
  MatDividerModule,
  MatSlideToggleModule,
  MatSnackBarModule
]

const GUARDS = [
  AppGuard,
  AuthGuard
]


@NgModule({
  declarations: [
    AppComponent,
    SlideToUnlockComponent,
    AppLayoutComponent,
    HomeComponent,
    GuestsComponent,
    ProfileComponent,
    GuestDialogComponent,
    AuthComponent,
    LoginComponent,
    LoginSuccessComponent,
    SecurityPinComponent,
    CheckLinkComponent,
    SystemTestComponent,
    ChangePinDialogComponent,
    QrCodeComponent
  ],
  imports: [
    ...MATERIAL,
    BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  exports: [
    RouterModule
  ],
  providers: [
    ...GUARDS,
    { provide: LOCALE_ID, useValue: "de-CH" },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'CHF' },
    provideAnimationsAsync(),
    provideRouter(routes),
    provideNativeDateAdapter(),
    provideHttpClient(withInterceptorsFromDi())
  ],
  bootstrap: [
    AppComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
