<div class="profile-container">

    <div class="page-title-container"><button mat-icon-button class="back-button"
            [routerLink]="['/home']"><mat-icon>arrow_back</mat-icon></button>
        <h3>Profil</h3>
        <div class="left-title"></div>
    </div>
    <p class="profile-text">Sollten Sie Änderungen an den Personalien wünschen, kontaktieren Sie bitte den Kundendienst.
    </p>

    <form [formGroup]="form" *ngIf="form">
        <mat-form-field appearance="outline">
            <mat-label>Vorname</mat-label>
            <input readonly type="text" matInput formControlName="firstname">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Nachname</mat-label>
            <input readonly type="text" matInput formControlName="lastname">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Adresse</mat-label>
            <input readonly matInput formControlName="address">
        </mat-form-field>
        <div class="form-field-row">
            <mat-form-field appearance="outline">
                <mat-label>PLZ</mat-label>
                <input readonly matInput formControlName="zip">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Ort</mat-label>
                <input readonly matInput formControlName="city">
            </mat-form-field>
        </div>
        <mat-form-field appearance="outline">
            <mat-label>E-Mail</mat-label>
            <input type="text" readonly matInput formControlName="email">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Mobile Nr.</mat-label>
            <input type="tel" readonly matInput formControlName="mobile">
        </mat-form-field>
    </form>

    <button mat-flat-button class="def-btn" (click)="newCode()">Geheimpin ändern
    </button>
</div>