<h1 mat-dialog-title>Geheimpin ändern<mat-icon
    [mat-dialog-close]="false">close</mat-icon></h1>
<mat-dialog-content>
<form [formGroup]="form" *ngIf="form">
    <mat-form-field appearance="outline">
        <mat-label>Alter Pin</mat-label>
        <input type="text" matInput formControlName="currentPin">
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Neuer Pin</mat-label>
        <input type="text" matInput formControlName="newPinA">
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Neuer Pin bestätigen</mat-label>
        <input type="text" matInput formControlName="newPinB">
    </mat-form-field>
</form>
</mat-dialog-content>
<mat-dialog-actions>
<button class="delete-btn" mat-button (click)="submit()">Vergessen
</button>
<button [disabled]=" form && !form.valid" class="save-btn" mat-flat-button (click)="submit()">Speichern
</button>
</mat-dialog-actions>
