import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AppLayoutComponent } from './shared/app-layout/app-layout.component';
import { GuestsComponent } from './guests/guests.component';
import { ProfileComponent } from './profile/profile.component';
import { AuthComponent } from './auth/auth.component';
import { LoginComponent } from './auth/login/login.component';
import { LoginSuccessComponent } from './auth/login-success/login-success.component';
import { SecurityPinComponent } from './auth/security-pin/security-pin.component';
import { CheckLinkComponent } from './auth/check-link/check-link.component';
import { AppGuard, AuthGuard } from './shared/guards';
import { SystemTestComponent } from './system-test/system-test.component';
import { QrCodeComponent } from './qr-code/qr-code.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'auth',
        component: AuthComponent,
        children: [
            {
                path: '',
                redirectTo: 'login',
                pathMatch: 'full'
            },
            {
                path: 'login',
                component: LoginComponent,
                canActivate:[AuthGuard],
            },
            {
                path: 'security-pin',
                component: SecurityPinComponent,
                canActivate:[AuthGuard],
            },
            {
                path: 'success',
                component: LoginSuccessComponent,
                canActivate:[AuthGuard],
            },
            {
                path: 'access/:access_code',
                component: CheckLinkComponent,
                canActivate:[AuthGuard],
            },
            {
                path: 'qr-code/:access_code',
                component: QrCodeComponent,
            },
        ]
    },
    {
        path: '',
        component: AppLayoutComponent,
        canActivate:[AppGuard],
        children: [
            {
                path: 'home',
                component: HomeComponent
            },
            {
                path: 'guests',
                component: GuestsComponent
            },
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'system-test',
                component: SystemTestComponent
            },
        ]
    },
];
