import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../shared/services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RoutesConstants } from '../../shared/constants';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-security-pin',
  templateUrl: './security-pin.component.html',
  styleUrl: './security-pin.component.scss'
})
export class SecurityPinComponent {
  public mobile: string;
  public form: FormGroup;
  public submitting: boolean = false;

  constructor(
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.initForm()
  }

  public initForm() {
    this.form = this.formBuilder.group({
      new_number: [, Validators.required],
      old_number: [, Validators.required],
      pin: [, Validators.required],
    })
  }

  public submit() {
    if (this.submitting) return;
    this.submitting = true;
    const jsonData = this.form.value;
    let formData = new FormData();
    formData.append('old-phonenumber', jsonData.old_number);
    formData.append('new-phonenumber', jsonData.new_number);
    formData.append('pin', jsonData.pin);
    this.loginService.pinLogin(formData).subscribe({
      next: data => {
        console.log(data)
        if (data.info == 'Data could be processed successfully') {
          this.router.navigate([RoutesConstants.LOGIN_SUCCESS]);
        }
      },
      error: err => {
        this.snackBar.open(err.error.message, undefined, { duration: 30000, panelClass: 'error-snack' });
        this.submitting = false;
      }
    })
  }

}
