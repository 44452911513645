import { Component, OnDestroy, OnInit } from '@angular/core';
import { LocationInterface, OverviewInterface } from '../shared/interfaces';
import { DevicesEnum } from '../shared/enums';
import { LocationService } from '../shared/services/locations.service';
import { MatSelectChange } from '@angular/material/select';
import { lastValueFrom, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit, OnDestroy {
  public locations: LocationInterface[] = [];
  public selectedLocation: number;
  public selectedType: DevicesEnum = DevicesEnum.storage;
  public devicesEnum = DevicesEnum;
  public overview: OverviewInterface;
  public connected: boolean = false;

  public $interval: NodeJS.Timeout;

  constructor(
    private locationService: LocationService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.locationService.getLocations().subscribe({
      next: data => {
        this.locations = data.locations;
        if (this.locations.length) {
          this.selectedLocation = this.locations[0].id

          this.getDevices()
          this.getOverviewStatus()
          this.$interval = setInterval(() => {
            this.getOverviewStatus()
          }, 3000);
        }
      }
    })

  }

  private getOverviewStatus() {
    this.locationService.getOverviewStatus(this.selectedLocation).subscribe({
      next: data => {
        if (data.data.length) {
          this.connected = data.data[0].visior_ip_status;
        }
        this.overview.areas.forEach(element => {
          const idx = data.data.findIndex((el) => el.area_id == element.id);
          if (idx != -1) {
            element.status_object = data.data[idx];
          }
        });
      },
      error: err => {
        console.log(err)
      }
    })
  }

  public getDevices() {
    this.locationService.overview(this.selectedLocation).subscribe({
      next: data => {
        this.overview = data;
      },
      error: err => {
        console.log(err)
      }
    })
  }

  public locationChanged(event: MatSelectChange) {
    this.selectedLocation = event.value
    this.getDevices()
  }

  public async unlocked(deviceId: number, deviceType: DevicesEnum, floor?: number) {
    try {
      if (deviceType == DevicesEnum.storage) {
        const body = { area_id: deviceId }
        const data = await lastValueFrom(this.locationService.openArea(body))
      } else {
        const body = { dyn_id: deviceId, floor: floor! }
        const data = await lastValueFrom(this.locationService.openElevator(body))
      }
      this.snackBar.open('Öffnung erfolgreich initiiert', undefined, { duration: 30000, panelClass: 'success-snack' })

    } catch (error) {
      this.snackBar.open((error as HttpErrorResponse).error.message, undefined, { duration: 30000, panelClass: 'error-snack' })
    }
  }

  public newType(type: DevicesEnum) {
    if (this.selectedType == type) return
    this.selectedType = type;
  }

  ngOnDestroy(): void {
    if (this.$interval)
      clearInterval(this.$interval);
  }
}
