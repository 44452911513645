import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public static setItem<T>(key: string, value: T): void {
    if (typeof value === 'object') {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, String(value));
    }
  }

  public static getItem<T>(key: string): T | null {
    const data = localStorage.getItem(key);

    if (data) {
      const isJsonString = this.isJsonString(data);
      return isJsonString ? JSON.parse(data) : data;
    } else {
      return null;
    }
  }

  public static removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  public static clear() {
    localStorage.clear();
  }

  private static isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}